<template>
  <div>
    <md-steppers md-vertical>
      <md-step id="first" md-label="Creación de producto" md-description="Campos obligatorios">
        <div class="md-layout md-alignment-center">
          <div class="md-layout-item md-size-90">
            <p>Información indispensable para crear un nuevo producto.</p>
          </div>
        </div>
        <div class="md-layout md-alignment-center">
          <div class="md-layout-item md-size-90">
            <md-field>
              <label>Número de parte</label>
              <md-input v-model="$v.product.sku.$model"/>
            </md-field>
            <div v-if="$v.product.sku.$dirty">
              <span class="md-helper-text" v-if="!$v.product.sku.required">Campo requerido</span>
              <span class="md-helper-text" v-if="!$v.product.sku.minLength">Minimo 2 caracteres</span>
            </div>
            <md-field>
              <label>Descripción</label>
              <md-input v-model="$v.product.description.$model"/>
            </md-field>
            <div v-if="$v.product.description.$dirty">
              <span class="md-helper-text" v-if="!$v.product.description.required">Campo requerido</span>
              <span class="md-helper-text" v-if="!$v.product.description.minLength">Minimo 2 caracteres</span>
            </div>
            <md-field>
              <label>OEM</label>
              <md-input v-model="$v.product.oem.$model"/>
            </md-field>
            <div v-if="$v.product.oem.$dirty">
              <span class="md-helper-text" v-if="!$v.product.oem.required">Campo requerido</span>
              <span class="md-helper-text" v-if="!$v.product.oem.minLength">Minimo 2 caracteres</span>
            </div>
            <div v-if="list.length < 1">
              <label>Categoría</label>
              <Missing />
            </div>
            <md-field v-else>
              <label>Categoría</label>
              <md-select v-model="$v.product.category_id.$model">
                <md-option v-for="category in list" :value="category.id">{{category.category}}</md-option>
              </md-select>
            </md-field>
            <div v-if="$v.product.category_id.$dirty">
              <span class="md-helper-text" v-if="!$v.product.category_id.required">Campo requerido</span>
            </div>
            <md-field>
              <label>Cantidad de piezas</label>
              <md-input v-model="$v.product.pieces.$model"/>
            </md-field>
            <div v-if="$v.product.pieces.$dirty">
              <span class="md-helper-text" v-if="!$v.product.pieces.required">Campo requerido</span>
              <span class="md-helper-text" v-if="!$v.product.pieces.numeric">Debe ser un valor entero</span>
            </div>
            <md-field>
              <label>Imagen</label>
              <md-input v-model="$v.product.image.$model"/>
            </md-field>
          </div>
        </div>
      </md-step>
      <md-step id="second" md-label="Atributos" md-description="Campos opcionales">
        <p>Atributos del producto (campos opcionales).</p>
        <div class="md-layout md-alignment-center">
          <div class="md-layout-item md-size-33">
            <md-field>
              <label>Diámetro interno #1</label>
              <md-input type="number" v-model="$v.product.internal_diameter_1.$model"/>
            </md-field>
            <md-field>
              <label>Diámetro interno #2</label>
              <md-input type="number" v-model="$v.product.internal_diameter_2.$model"/>
            </md-field>
            <md-field>
              <label>Diámetro externo #1</label>
              <md-input type="number" v-model="$v.product.external_diameter_1.$model"/>
            </md-field>
            <md-field>
              <label>Diámetro externo #2</label>
              <md-input type="number" v-model="$v.product.external_diameter_2.$model"/>
            </md-field>
          </div>
          <div class="md-layout-item md-size-33">
            <md-field>
              <label>Alto</label>
              <md-input type="number" v-model="$v.product.height.$model"/>
            </md-field>
            <md-field>
              <label>Ancho</label>
              <md-input type="number" v-model="$v.product.width.$model"/>
            </md-field>
            <md-field>
              <label>Largo</label>
              <md-input type="number" v-model="$v.product.length.$model"/>
            </md-field>
          </div>
          <div class="md-layout-item md-size-33">
            <md-field>
              <label>UPC</label>
              <md-input v-model="$v.product.upc.$model"/>
            </md-field>
            <md-field>
              <label>Micron</label>
              <md-input v-model="$v.product.micron.$model"/>
            </md-field>
            <md-field>
              <label>Notas</label>
              <md-input v-model="$v.product.connections.$model"/>
            </md-field>
            <md-field>
              <label>Contiene</label>
              <md-input v-model="$v.product.standpipe.$model"/>
            </md-field>
          </div>
        </div>
      </md-step>
    </md-steppers>
    <div class="md-layout md-alignment-center">
      <div class="md-layout-item md-size-95">
        <md-button class="md-raised" @click="create(product)">Crear nuevo producto</md-button>
      </div>
    </div>
  </div>
</template>
<script>
import Missing from '@/components/Common/Missing.vue'
import validations from '@/validations/products'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Create',
  components: {
    Missing
  },
  data() {
    return {
      product: {
        sku: '', description: '', oem: '', category_id: '', external_diameter_1: '', external_diameter_2: '',
        internal_diameter_1: '', internal_diameter_2: '', image: '', height: '', width: '',
        length: '', upc: '', micron: '', connections: '', standpipe: '', pieces: ''
      }
    }
  },
  validations: validations,
  mounted() {
    this.categories()
  },
  computed: {
    ...mapGetters({list: 'categories/listCategories'})
  },
  methods: {
    ...mapActions({categories: 'categories/getProductCategories'}),
    create(product) {
      if (!this.$v.$invalid) {
        this.$store.dispatch('products/addProduct', product).then(() => {
          Object.assign(this.$data, this.$options.data.apply(this))
          this.$nextTick(() => { this.$v.$reset() })
        })
      }
    }
  }
}
</script>
