<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-tabs md-alignment="left">
        <md-tab id="tab-home" md-label="Listado de productos">
          <List />
        </md-tab>
        <md-tab id="tab-pages" md-label="Crear nuevo producto">
          <Create />
        </md-tab>
      </md-tabs>
    </div>
  </div>
</template>
<script>
import Create from '@/components/Products/Create.vue'
import List from '@/components/Products/List.vue'
import auth from '@/mixins/auth'
export default {
  name: 'Products',
  mixins: [auth],
  components: {
    List, Create
  }
}
</script>
